<template>
  <b-container fluid>
    <b-row>
      <b-col cols="8">
        <b-form-group>
          <label for="procedure">Procedimento</label>
          <ItemSelector id="procedure" :item="surgeryProcedure" />
        </b-form-group>
      </b-col>

      <b-col cols="4">
        <b-form-group>
          <label for="laterality">Lateralidade</label>
          <b-skeleton v-if="isLoading" width="100%" height="38px" />
          <multiselect
            v-else
            id="laterality"
            v-model="surgeryProcedure.laterality"
            class="with-border"
            :options="lateralities"
            :show-labels="false"
            placeholder="Selecionar"
            :loading="isLoading || loadingKits"
            :disabled="isLoading || loadingKits"
          >
            <template #caret>
              <div class="chevron">
                <ChevronDown />
              </div>
            </template>
            <template #noResult>
              <div class="multiselect__option">Nenhum resultado encontrado</div>
            </template>
          </multiselect>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12">
        <b-form-group>
          <label for="observations">Observação</label>
          <b-skeleton v-if="isLoading" width="100%" height="54px" />
          <TextArea
            v-else
            id="observations"
            :value="surgeryProcedure.observations"
            @inputTextArea="surgeryProcedure.observations = $event"
            :readonly="isLoading"
            placeholder="Descrever observação"
          />
        </b-form-group>
      </b-col>
    </b-row>

    <div class="participants">
      <ParticipantSelectModule
        v-for="(participant, index) in participants"
        :key="index"
        :participant="participant"
        :order="index"
        :removeParticipant="removeParticipant"
        :beneficiaries="beneficiaries"
        :isLoading="isLoading"
        :participants="participants"
      />
    </div>
    <b-row class="justify-content-between">
      <b-col cols="9" class="d-flex mb-4 mt-2">
        <b-button variant="link" @click="addParticipant" :disabled="isLoading">
          <Plus class="icon mr-2" />
          Adicionar participante
        </b-button>

        <b-button
          v-if="copyTeam"
          variant="link"
          @click="copyTeam(surgeryProcedure)"
          :disabled="isLoading"
        >
          <Icon tooltip="Copiar a equipe do procedimento acima">
            <Users class="icon users" />
            Copiar equipe
          </Icon>
        </b-button>
      </b-col>
      <div v-if="manageMatmed">
        <b-button
          variant="primary"
          v-b-tooltip.hover
          class="d-flex align-items-center"
          :disabled="!surgeryInformationId"
          @click="goToMatMedManagement"
        >
          <Clipboard class="clipboard" />
          Gerenciar Mat/med
        </b-button>
      </div>
    </b-row>
    <hr />
    <SurgeryProductsTemplateModal
      :id="`surgery-products-template-modal-${index}`"
      :kitTemplates="kitTemplates"
      @add-kit-template="addSurgeryKitTemplates"
    />
  </b-container>
</template>

<script>
export default {
  name: 'SurgeryData',
  components: {
    ItemSelector: () => import('@/components/General/ItemSelector'),
    ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
    Plus: () => import('@/assets/icons/plus.svg'),
    Icon: () => import('@/components/General/Icon'),
    Users: () => import('@/assets/icons/users.svg'),
    ParticipantSelectModule: () => import('./ParticipantSelectModule'),
    TextArea: () => import('@/components/General/TextArea'),
    Clipboard: () => import('@/assets/icons/clipboard.svg'),
    SurgeryProductsTemplateModal: () => import('./SurgeryProductsTemplateModal')
  },
  props: {
    index: Number,
    surgeryInformationId: String,
    surgeryProcedure: {
      type: Object,
      default: () => {}
    },
    participants: {
      type: Array,
      default: () => []
    },
    copyTeam: {
      type: Function,
      default: () => {}
    },
    beneficiaries: {
      type: Array,
      default: () => []
    },
    isLoading: Boolean,
    manageMatmed: Boolean
  },
  data: () => ({
    lateralities: ['Olho esquerdo', 'Olho direito', 'Ambos os olhos'],
    laterality: null,
    loadingKits: false,
    kitTemplates: []
  }),
  created() {
    this.getProcedureKitTemplates()
  },
  methods: {
    addParticipant() {
      this.participants.push({
        type: '',
        beneficiary_id: null,
        index: this.participants.length
      })
    },
    removeParticipant(index) {
      const indexToRemove = this.participants.findIndex(
        participant => participant.index === index
      )
      this.participants.splice(indexToRemove, 1)
    },
    goToMatMedManagement() {
      this.$router.push(`centro-cirurgico/matmed/${this.surgeryInformationId}`)
    },
    async getProcedureKitTemplates() {
      try {
        this.loadingKits = true
        const { data } = await this.api.getProcedureKitTemplates(
          this.surgeryProcedure?.clinic_procedure_id,
          null
        )
        this.kitTemplates = data.map(el => el?.kit_template) ?? []
      } catch (err) {
        this.$toast.error(err.message)
      } finally {
        this.loadingKits = false
      }
    },
    async manageSurgeryProducts() {
      // if (this.kitTemplates?.length)
      // this.$bvModal.show(`surgery-products-template-modal-${this.index}`)
    },
    addSurgeryKitTemplates(kitTemplates) {
      const productTemplates = []
      kitTemplates.map(kit => {
        kit.products.map(product => {
          productTemplates.push({
            surgery_procedure_id: this.surgeryProcedure?.id,
            kit_template_id: kit.id,
            product_id: product?.id,
            quantity: product?.pivot?.quantity
          })
        })
      })

      this.$set(
        this.surgeryProcedure,
        'surgery_products_templates',
        productTemplates
      )
    }
  },
  watch: {
    'surgeryProcedure.laterality': function (newValue, oldValue) {
      if (newValue && oldValue === null) this.manageSurgeryProducts()
    }
  }
}
</script>

<style lang="scss" scoped>
.icon {
  width: 18px !important;
  height: 18px !important;
  fill: var(--blue-500);

  &.users {
    fill: none !important;
    stroke: var(--blue-500) !important;
  }
}

.clipboard {
  transform: scale(0.7);
  stroke: var(--neutral-100);
}
</style>
